/**
 * Created by flo on 2/17/16.
 */
function floSendMail( form, container){
  jQuery('#flo-name').removeClass('invalid');
  jQuery('#flo-email').removeClass('invalid');

  jQuery(container).html('');

  //jQuery('#flo-loading').css('background','rgba(255,255,255,0.2)');
  //jQuery('#flo-loading').fadeIn('slow'); // loading effect
  jQuery.ajax({
    url: ajaxurl,
    data: '&action=floSendContact&'+jQuery( form ).serialize(),
    type: 'POST',
    dataType: "json",
//      cache: false,
    success: function (json) {

      //jQuery('#flo-loading').fadeOut('slow'); // loading effect

      if(json.contact_name ){
        jQuery('#flo-name').addClass('invalid');
        jQuery(container).append(json.contact_name);
      }

      if(json.contact_name ){
        jQuery('#location').addClass('invalid');
        jQuery(container).append(json.contact_location);
      }

      if(json.contact_name ){
        jQuery('#date').addClass('invalid');
        jQuery(container).append(json.contact_date);
      }
      if(json.contact_name ){
        jQuery('#question').addClass('invalid');
        jQuery(container).append(json.contact_question);
      }

      if(json.contact_email ){
        jQuery('#flo-email').addClass('invalid');
        jQuery(container).append(json.contact_email);
      }

      if(json.message ){
        jQuery('.flo-modal').fadeIn('slow');

        jQuery( form).find('input[type="text"], textarea').val('');

        setTimeout(function(){
          jQuery('.flo-modal').fadeOut('slow');
        },3000);
      }

    }
  });
}

(function ($) {
  var on = $.fn.on, timer;
  $.fn.on = function () {
      var args = Array.apply(null, arguments);
      var last = args[args.length - 1];

      if (isNaN(last) || (last === 1 && args.pop())) return on.apply(this, args);

      var delay = args.pop();
      var fn = args.pop();

      args.push(function () {
          var self = this, params = arguments;
          clearTimeout(timer);
          timer = setTimeout(function () {
              fn.apply(self, params);
          }, delay);
      });

      return on.apply(this, args);
  };
}(this.jQuery || this.Zepto));

(function(window, $, undefined){
  "use strict";

  var breakpoint_small = 750,
      breakpoint_medium = 1024;

  var isDesktop = function() {
    return jQuery(document).width() > breakpoint_medium;
  };

  var isTablet = function() {
    return jQuery(document).width() <= breakpoint_medium && jQuery(document).width() > breakpoint_small;
  };

  var isMobile = function() {
    return jQuery(document).width() <= breakpoint_small;
  };

  var rem = function() {
    return parseInt($("html").css("font-size"), 10);
  }

  $(document).foundation();

  // var floConfig = {
  //   page_padding: 50
  // };

  // Resize ended
  var resizeEnded_timeout;
  window.onresize = function(){
    clearTimeout(resizeEnded_timeout);
    resizeEnded_timeout = setTimeout(function(){
      $(window).trigger("resizeEnded");
    }, 100);
  };

  $(function() {
    FastClick.attach(document.body);
  });
// Type Masonry
$(document).ready(function() {
  var do_masonry, isActive, type_grid_masonry;
  type_grid_masonry = $(".flo_post_list.type_grid.masonry, .flo_post_list.type_grid_text_under.masonry");
  if (type_grid_masonry.length) {
    do_masonry = function() {
      return type_grid_masonry.masonry({
        itemSelector: ".flo_post"
      });
    };
    type_grid_masonry.find("img").load(function() {
      return do_masonry();
    });
    $(window).resize(function(){
      do_masonry();
    }).trigger("resize");
    return isActive = true;
  }
});

// View Type Slider
$(document).ready(function(){

  $(".flo_post_list.view_type_slider .flo_post_list_posts").slick({
    dots: false,
    arrows: true,
    adaptiveHeight: true,
    prevArrow: ".flo_post_list_slider_controls .prev",
    nextArrow: ".flo_post_list_slider_controls .next"
  });

});

// Slider types 
$(function(){
  // Generic Sliders
  var sliders = [
    "slider_1",
    "slider_2",
    "slider_3"
  ];
  $.each(sliders, function(index, value){
    sliders[index] = ".type_" + value + " .flo_post_list_posts";
  });
  sliders = sliders.join(", ");

  var $sliders = $(sliders);
  $sliders.slick({
    fade: true,
    dots: true,
    adaptiveHeight: true
  });

  // Slider 4
  $(".type_slider_4 .flo_post_list_posts").slick({
    fade: true,
    dots: false, 
    adaptiveHeight: true,
    prevArrow: ".description_wrap .flo_prev",
    nextArrow: ".description_wrap .flo_next"
  });
  $(".flo_prev, .flo_next").on("click", function(e){
    e.preventDefault();
  });

  // Slider 5
  var $slider_5 = $(".type_slider_5 .flo_post_list_posts");

  $slider_5.on("afterChange init", function(){
    var
    BackgroundCheck_classes = { 
      dark: 'dark',
      light: 'light',
      complex: 'complex'
    },
    BackgroundCheck_targets = [
      ".type_slider_5 .slick-prev",
      ".type_slider_5 .slick-next",
      ".type_slider_5 .title_wrap",
      ".type_slider_5 .meta_wrap",
    ].join(", ")
    ;
    $(BackgroundCheck_targets).removeClass("dark").removeClass("light");
    BackgroundCheck.init({
      targets: BackgroundCheck_targets,
      images: ".type_slider_5 .slick-current .feat_img_bg",
      classes: BackgroundCheck_classes,
      windowEvents: false
    });
    BackgroundCheck.refresh();
  });

  $slider_5.slick({
    fade: true,
    dots: false
  });
});

/* POST SLIDER START */ 
$(function (){
  var 
  $slider = $(".flo_post .flo_post_slider"),
  $next = $slider.children(".flo_post_slider_arrow_next"),
  $prev = $slider.children(".flo_post_slider_arrow_prev")
  ; 

  $next.on("click", function(e){
    e.preventDefault();
    var
    $slider = $(this).parent(),
    slides_count = $slider.children("input:last").data("slide-count"),
    $checked = $slider.children("input:checked"),
    $first = $slider.children("input:first"),
    index = $checked.data("slide-count"),
    $next = $checked.nextAll("input").first()
    ;
    if ($next.length) {
      $next.prop( "checked", true )
    }
    else {
      $first.prop( "checked", true );
    }
  });

  $prev.on("click", function(e){
    e.preventDefault();
    var
    $slider = $(this).parent(),
    slides_count = $slider.children("input:last").data("slide-count"),
    $checked = $slider.children("input:checked"),
    $last = $slider.children("input:last"),
    index = $checked.data("slide-count"),
    $prev = $checked.prevAll("input").first()
    ;
    if ($prev.length) {
      $prev.prop( "checked", true )
    }
    else {
      $last.prop( "checked", true );
    }
  });
});
/* POST SLIDER END */ 

/* VIDEO IN FEAT IMG WRAP START */
$(function(){
  var
  $container = $(".flo_post_list .feat_img_wrap"),
  $button_play = $container.find(".button_play")
  ;
  $button_play.click(function(e){
    var
    $container = $(this).parent()
    ;
    $container.floVideo("init");
    $container.floVideo("create");
  });
});
/* VIDEO IN FEAT IMG WRAP END */})(window, jQuery.noConflict());