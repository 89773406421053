/**
 * Created by flo on 2/17/16.
 */
function floSendMail( form, container){
  jQuery('#flo-name').removeClass('invalid');
  jQuery('#flo-email').removeClass('invalid');

  jQuery(container).html('');

  //jQuery('#flo-loading').css('background','rgba(255,255,255,0.2)');
  //jQuery('#flo-loading').fadeIn('slow'); // loading effect
  jQuery.ajax({
    url: ajaxurl,
    data: '&action=floSendContact&'+jQuery( form ).serialize(),
    type: 'POST',
    dataType: "json",
//      cache: false,
    success: function (json) {

      //jQuery('#flo-loading').fadeOut('slow'); // loading effect

      if(json.contact_name ){
        jQuery('#flo-name').addClass('invalid');
        jQuery(container).append(json.contact_name);
      }

      if(json.contact_name ){
        jQuery('#location').addClass('invalid');
        jQuery(container).append(json.contact_location);
      }

      if(json.contact_name ){
        jQuery('#date').addClass('invalid');
        jQuery(container).append(json.contact_date);
      }
      if(json.contact_name ){
        jQuery('#question').addClass('invalid');
        jQuery(container).append(json.contact_question);
      }

      if(json.contact_email ){
        jQuery('#flo-email').addClass('invalid');
        jQuery(container).append(json.contact_email);
      }

      if(json.message ){
        jQuery('.flo-modal').fadeIn('slow');

        jQuery( form).find('input[type="text"], textarea').val('');

        setTimeout(function(){
          jQuery('.flo-modal').fadeOut('slow');
        },3000);
      }

    }
  });
}

(function ($) {
  var on = $.fn.on, timer;
  $.fn.on = function () {
      var args = Array.apply(null, arguments);
      var last = args[args.length - 1];

      if (isNaN(last) || (last === 1 && args.pop())) return on.apply(this, args);

      var delay = args.pop();
      var fn = args.pop();

      args.push(function () {
          var self = this, params = arguments;
          clearTimeout(timer);
          timer = setTimeout(function () {
              fn.apply(self, params);
          }, delay);
      });

      return on.apply(this, args);
  };
}(this.jQuery || this.Zepto));

(function(window, $, undefined){
  "use strict";

  var breakpoint_small = 750,
      breakpoint_medium = 1024;

  var isDesktop = function() {
    return jQuery(document).width() > breakpoint_medium;
  };

  var isTablet = function() {
    return jQuery(document).width() <= breakpoint_medium && jQuery(document).width() > breakpoint_small;
  };

  var isMobile = function() {
    return jQuery(document).width() <= breakpoint_small;
  };

  var rem = function() {
    return parseInt($("html").css("font-size"), 10);
  }

  $(document).foundation();

  // var floConfig = {
  //   page_padding: 50
  // };

  // Resize ended
  var resizeEnded_timeout;
  window.onresize = function(){
    clearTimeout(resizeEnded_timeout);
    resizeEnded_timeout = setTimeout(function(){
      $(window).trigger("resizeEnded");
    }, 100);
  };

  $(function() {
    FastClick.attach(document.body);
  });
// Slider initialization
$(document).ready(function() {
  var init_slider = function(selector, custom_options, callback) {
    var
    $slider = $(selector),
    general_options = {
      arrows: true,
      lazyLoad: "ondemand",
      cssEase: "linear",
      pauseOnHover: $slider.parent().data("pause_on_hover") == "enabled" ? true : false,
      autoplay: $slider.parent().data("autoplay") == "enabled" ? true : false,
      autoplaySpeed: $slider.parent().data("autoplay_speed") != undefined ? $slider.parent().data("autoplay_speed") : 3000,
      responsive: [
        {
          breakpoint: 736,
          settings: {
            slidesToShow: 1,
            dots: false,
            arrows: true,
            fade: true,
            adaptiveHeight: true,
            variableWidth: false
          }
        }
      ],
    },
    options = $.extend(general_options, custom_options)
    ;
    if ($slider.length) {

      jQuery.fn.extend({
        ensureLoad: function(handler) {
          return this.each(function() {
            if(this.complete) {
              handler.call(this);
            } else {
              $(this).load(handler);
            }
          });
        }
      });

      // "Smooth" slide loading
      $slider.on("init reinit", function(e, slick){
        // $slider.slick("setPosition");

        // $slider.find(".slide").prev().find("img").on("loaded", function(){
        //   $(this).parent().next().css('opacity','1');
        // });

        $slider.find(".slide").find("img").on("loaded", function(){
          $(this).parent().css('opacity','1');
        });

        $slider.find(".slide").filter(function(){
          // return $(this).attr("data-slick-index") >= 0
          return true
        }).each(function(){
          $(this).find("img").ensureLoad(
            function(){
              $(this).trigger("loaded");
            }
          );
        });

      });

      $slider.on("init reinit", function(){
        if (typeof callback == "function") callback($slider);
      });

      $slider.slick(options);
    }
  }

  var
  type_single_selector = ".flo_slider.type_single .slick",
  $type_single = $(type_single_selector),
  type_single_fade = $type_single.parent().data("slide_effect") == "slide" ? false : true
  ;
  init_slider(".flo_slider.type_single .slick", {
    lazyLoad: "progressive",
    slidesToShow: 1,
    dots: false, // dots: true
    fade: type_single_fade
  }, function($slider){
    // Crop Slider lazy load
    $slider.on("beforeChange", function(event, slick, currentSlide, nextSlide){
      var
      next_slide = $(slick.$slides.get(nextSlide)),
      next_slide_bg_url = next_slide.data("lazy-bg")
      ;
      if (next_slide_bg_url)
        next_slide.css("background-image", "url('" + next_slide.data("lazy-bg") + "')");
    });
  });

  var
  type_visible_nearby_selector = ".flo_slider.type_visible_nearby .slick",
  $type_visible_nearby = $(type_visible_nearby_selector),
  type_visible_nearby_slides_count = $type_visible_nearby.find(".slide").length,
  type_visible_nearby_slidesToShow = type_visible_nearby_slides_count >= 3 ? 3 : 1, /*slides_count*/
  type_visible_nearby_center = $type_visible_nearby.parent().data("slide_alignment") == "center" ? true : false
  ;
  init_slider(".flo_slider.type_visible_nearby .slick", {
    lazyLoad: "progressive",
    dots: false, // dots: true
    variableWidth: true,
    centerMode: type_visible_nearby_center
  });

  var
  type_contain_variable_selector = ".flo_slider.type_contain_variable .slick"
  ;
  init_slider(type_contain_variable_selector, {
    slidesToShow: 1,
    dots: false,
    arrows: true,
    fade: true,
    adaptiveHeight: true,
    variableWidth: false,
    lazyLoad: "progressive"
  }, function($slider){
    // Crop Slider lazy load
    // $slider.on("beforeChange", function(event, slick, currentSlide, nextSlide){
    //   var
    //   next_slide = $(slick.$slides.get(nextSlide)),
    //   next_slide_bg_url = next_slide.data("lazy-bg")
    //   ;
    //   if (next_slide_bg_url)
    //     next_slide.css("background-image", "url('" + next_slide.data("lazy-bg") + "')");
    // });
  });

  var
  type_contain_selector = ".flo_slider.type_contain .slick"
  ;
  init_slider(type_contain_selector, {
    slidesToShow: 1,
    dots: true,
    fade: true,
  }, function($slider){
    // Crop Slider lazy load
    $slider.on("beforeChange", function(event, slick, currentSlide, nextSlide){
      var
      next_slide = $(slick.$slides.get(nextSlide)),
      next_slide_bg_url = next_slide.data("lazy-bg")
      ;
      if (next_slide_bg_url)
        next_slide.css("background-image", "url('" + next_slide.data("lazy-bg") + "')");
    });
  });
});

// Video slide works
$(document).ready(function(){
  var
  video_slide = $(".slide.video"),
  button_play = video_slide.find(".button_play")
  ;

  // Start Video on Play button click
  button_play.click(function(){
    var
    button = $(this),
    slide = button.parent().parent();
    slide.floVideo("init");
    slide.floVideo("create");
    $(".flo_slider .slick").slick("slickPause");
  });

  // Destroy Video on slide change
  $(".flo_slider .slick").on("beforeChange", function(){
    $(this).parent().data("autoplay") == "enabled" ? $(this).slick("slickPlay") : false;
    $(this).find(".video-active").floVideo("destroy");
  });

});
})(window, jQuery.noConflict());