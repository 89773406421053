/**
 * Created by flo on 2/17/16.
 */
function floSendMail( form, container){
  jQuery('#flo-name').removeClass('invalid');
  jQuery('#flo-email').removeClass('invalid');

  jQuery(container).html('');

  //jQuery('#flo-loading').css('background','rgba(255,255,255,0.2)');
  //jQuery('#flo-loading').fadeIn('slow'); // loading effect
  jQuery.ajax({
    url: ajaxurl,
    data: '&action=floSendContact&'+jQuery( form ).serialize(),
    type: 'POST',
    dataType: "json",
//      cache: false,
    success: function (json) {

      //jQuery('#flo-loading').fadeOut('slow'); // loading effect

      if(json.contact_name ){
        jQuery('#flo-name').addClass('invalid');
        jQuery(container).append(json.contact_name);
      }

      if(json.contact_name ){
        jQuery('#location').addClass('invalid');
        jQuery(container).append(json.contact_location);
      }

      if(json.contact_name ){
        jQuery('#date').addClass('invalid');
        jQuery(container).append(json.contact_date);
      }
      if(json.contact_name ){
        jQuery('#question').addClass('invalid');
        jQuery(container).append(json.contact_question);
      }

      if(json.contact_email ){
        jQuery('#flo-email').addClass('invalid');
        jQuery(container).append(json.contact_email);
      }

      if(json.message ){
        jQuery('.flo-modal').fadeIn('slow');

        jQuery( form).find('input[type="text"], textarea').val('');

        setTimeout(function(){
          jQuery('.flo-modal').fadeOut('slow');
        },3000);
      }

    }
  });
}

(function ($) {
  var on = $.fn.on, timer;
  $.fn.on = function () {
      var args = Array.apply(null, arguments);
      var last = args[args.length - 1];

      if (isNaN(last) || (last === 1 && args.pop())) return on.apply(this, args);

      var delay = args.pop();
      var fn = args.pop();

      args.push(function () {
          var self = this, params = arguments;
          clearTimeout(timer);
          timer = setTimeout(function () {
              fn.apply(self, params);
          }, delay);
      });

      return on.apply(this, args);
  };
}(this.jQuery || this.Zepto));

(function(window, $, undefined){
  "use strict";

  var breakpoint_small = 750,
      breakpoint_medium = 1024;

  var isDesktop = function() {
    return jQuery(document).width() > breakpoint_medium;
  };

  var isTablet = function() {
    return jQuery(document).width() <= breakpoint_medium && jQuery(document).width() > breakpoint_small;
  };

  var isMobile = function() {
    return jQuery(document).width() <= breakpoint_small;
  };

  var rem = function() {
    return parseInt($("html").css("font-size"), 10);
  }

  $(document).foundation();

  // var floConfig = {
  //   page_padding: 50
  // };

  // Resize ended
  var resizeEnded_timeout;
  window.onresize = function(){
    clearTimeout(resizeEnded_timeout);
    resizeEnded_timeout = setTimeout(function(){
      $(window).trigger("resizeEnded");
    }, 100);
  };

  $(function() {
    FastClick.attach(document.body);
  });
/* MENU BURGER TOGGLE START */
$(document).ready(function(){
  var 
  // Default animation
  animation_in = "fade-in",
  animation_out = "fade-out",
  opened_class = "flo_burger_opened",
  burger_menu_close = function (){
    MotionUI.animateOut(".flo_header_menu", animation_out);
    $(".flo_header .burger_wrap").removeClass(opened_class);
    $("body").removeClass(opened_class);
  }
  $(".flo_burger_menu_button_close").on("click", function(){
    burger_menu_close();
  });
  $(".flo_header .burger_wrap").on("click", function(){
    var
    $this = $(this),


    is_opened = function(){return $this.hasClass("opened")}

    //  Mobile animation
    // if (isMobile) {
    //   animation_in = "fade-in";
    //   animation_out = "fade-out";
    // }

    $(document).keyup(function(e) {
        if (e.keyCode == 27) {
          burger_menu_close();
        }
    });  

    if (is_opened()) {
      burger_menu_close();
    } else if (!is_opened()) {
      MotionUI.animateIn(".flo_header_menu", animation_in, function(){
        $(window).trigger("flo_header_menu_do_center");
      });
      $this.addClass(opened_class);
      $("body").addClass(opened_class);
    }
  });
});
/* MENU BURGER TOGGLE END */

/* MENU POSITION AROUND START */
$(document).ready(function() {
  var $header, $left_side, $logo, $menu, $menu_items, $right_side, header_class;
  header_class = "header.menu_position_around";
  if ($(header_class).length) {
    $header = $(header_class);
    $menu = $header.find(".flo_header_menu > ul");
    $menu_items = $menu.children("li");
    $logo = $header.children(".flo_header_logo");
    $left_side = $("<ul/>");
    $right_side = $("<ul/>");
    $menu_items.each(function(index, item) {
      var length;
      length = $menu_items.length;
      if (index < length / 2) {
        return $left_side.append($(item).clone());
      } else if (index >= length / 2) {
        return $right_side.append($(item).clone());
      }
    });
    $left_side = $("<div class='left_side'/>").append($left_side);
    $right_side = $("<div class='right_side'/>").append($right_side);
    return $header.append($("<div class='flo_header_menu_and_logo'>").append($left_side).append($logo.clone()).append($right_side));
  }

  $('.comment-reply-link').click(function(){
    //console.log($('#comment_parent').val());
    if($('#comment_parent').val() != '0'){
      $('.comment-reply-link').show();
      var cancel = $('#cancel-comment-reply-link');
      $(this).hide();
      $(this).after(cancel);
      $('#cancel-comment-reply-link').css('margin-left','5px');
      //$('#reply-title .reply_text').html('LEAVE YOUR REPLY');
    }else{
      //$('#reply-title .reply_text').html('LEAVE YOUR COMMENT');
      $(this).show();
    }
  })
  $('#cancel-comment-reply-link').click(function(){
    //$('#reply-title .reply_text').html('LEAVE YOUR COMMENT');
    $('.comment-reply-link').show();
  })
});
/* MENU POSITION AROUND END */

/* MENU DROPDOWN START */
$(document).ready(function(){
  var menu_items_places = [".flo_header_menu", ".left_side", ".right_side"];
  menu_items_places.forEach(function(place_selector, index){
    menu_items_places[index] = ".flo_header " + place_selector + " > ul > li";
  });
  var $menu_items = $(menu_items_places.join(" , "));

  var hover_timeout = 500;
  var dropdown_animate = function($menu_items, animation_in, animation_out){
    $menu_items.hoverIntent({
      over: function(){
        if (isDesktop()) MotionUI.animateIn($(this).children("ul"), animation_in);
      },
      out: function(){
        if (isDesktop()) MotionUI.animateOut($(this).children("ul"), animation_out);
      },
      timeout: hover_timeout
    });    
  }

  // Second level menu
  dropdown_animate(
    $menu_items,
    "fade-in",
    "fade-out"
  );

  // Third level menu
  var verification = function(element){
    return $(element).parent().parent().is("li:last-child") || $(element).parent().parent().is("li:nth-last-child(2)")
  }
  $menu_items.children("ul").find("li").hoverIntent({
    over: function(){
      var $submenu = $(this).children("ul"),
        animation_in = "hinge-in-from-left",
        last_animation_in = "hinge-in-from-right";
      if (isDesktop()) {
        if (verification(this)) {
          MotionUI.animateIn($submenu, last_animation_in);
        } else {
          MotionUI.animateIn($submenu, animation_in);
        }
      }
    },
    out: function(){
      var $submenu = $(this).children("ul"),
      animation_out = "hinge-out-from-left",
      last_animation_out = "hinge-out-from-right";
      if (isDesktop()) {
        if (verification(this)) {
          MotionUI.animateOut($submenu, last_animation_out);
        } else {
          MotionUI.animateOut($submenu, animation_out);
        }
      }
    },
    timeout: hover_timeout
  });

  // Fix Dropdowns for Tablet and Desktop by removing all the inline styles left by MotionUI
  var current_state_tablet,
      current_state_desktop,
      fix_dropdowns = function() {
        $(".flo_header *").attr("style", ""); 
      }
  $(window).on("resizeEnded", function(){
    if (current_state_tablet !== isTablet()) {
      current_state_tablet = isTablet();
      fix_dropdowns();
    } 
    if (current_state_desktop !== isDesktop()) {
      current_state_desktop = isDesktop();
      fix_dropdowns();
    } 
  }).trigger("resizeEnded");

  // Center second level elements
  var center_second_level_items = function (){
    $menu_items.each(function(){
      var
      parent = $(this),
      parent_width = parent.width(),
      current = parent.children("ul"),
      current_width = current.width()
      ;
      current.css({
        "left": (parent_width/2 - current_width/2)/rem() + "rem",
        "right": "initial"
      })  
    });
  }
  center_second_level_items();
  $(window).on("resizeEnded, flo_header_menu_do_center", function(){
    if (isDesktop()) center_second_level_items();
  });

});

/*Tweets widget*/
var delay = 4000; //millisecond delay between cycles
jQuery(".dynamic .tweet_item").css('display', 'none');
function cycleThru(variable, j) {
  var jmax = jQuery(variable + " div").length;
  jQuery(variable + " div:eq(" + j + ")")
      .css('display', 'block')
      .animate({opacity: 1}, 600)
      .animate({opacity: 1}, delay)
      .animate({opacity: 0}, 800, function () {
        if (j + 1 === jmax) {
          j = 0;
        } else {
          j++;
        }
        jQuery(this).css('display', 'none').animate({opacity: 0}, 10);
        cycleThru(variable, j);
      });
}

jQuery('.tweets').each(function (index, val) {
  //iterate through array or object
  var parent_tweets = jQuery(val).parent().attr('id');
  var actioner = '#' + parent_tweets + ' .tweets .dynamic .flo_twitter .slides_container';
  cycleThru(actioner, 0);
});
/* MENU DROPDOWN END */

/* SCROLL DOWN START */
$(document).ready(function(){
  $(".flo_header_scroll_down").on("click", function(){
    $('html, body').animate({scrollTop: ($(window).scrollTop() + window.innerHeight) + 'px'}, 300);   
  });
});
/* SCROLL DOWN END */

/* ITEMS COLOR ON FLO FEATURED SLIDER IF BACKGROUND CHECK IS DISABLED START */
$(function(){
  if($('.flo_featured_image_or_slider').length > 0){
    var $menu_color, $logo;
    $menu_color = floConfig.menu_slideshow_color;
    $("body").append("<style>" +
        "@media screen and (min-width: 1024px) {" +
            " .flo_header," +
            " .flo_header .flo-icon-flo-arrow-left.flo_header_scroll_down__arrow," +
            " .flo_header_menu > ul > li > a .title," +
            " .flo_pagination_type_single_post .prev_post_link a span.title," +
            ".flo_slider .slick-next:before, .flo_slider .slick-prev:before," +
            "a.flo_logo{color:"+$menu_color+"}" +
            ".flo_header_on_flo_slider .flo_featured_image_or_slider.size-full_screen:after," +
            ".flo_header:before{background-color: "+$menu_color+"}" +
        " }" +
        "</style>")
  }
});

/* ITEMS COLOR ON FLO FEATURED SLIDER IF BACKGROUND CHECK IS DISABLED END */})(window, jQuery.noConflict());