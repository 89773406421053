/**
 * Created by flo on 2/17/16.
 */
function floSendMail( form, container){
  jQuery('#flo-name').removeClass('invalid');
  jQuery('#flo-email').removeClass('invalid');

  jQuery(container).html('');

  //jQuery('#flo-loading').css('background','rgba(255,255,255,0.2)');
  //jQuery('#flo-loading').fadeIn('slow'); // loading effect
  jQuery.ajax({
    url: ajaxurl,
    data: '&action=floSendContact&'+jQuery( form ).serialize(),
    type: 'POST',
    dataType: "json",
//      cache: false,
    success: function (json) {

      //jQuery('#flo-loading').fadeOut('slow'); // loading effect

      if(json.contact_name ){
        jQuery('#flo-name').addClass('invalid');
        jQuery(container).append(json.contact_name);
      }

      if(json.contact_name ){
        jQuery('#location').addClass('invalid');
        jQuery(container).append(json.contact_location);
      }

      if(json.contact_name ){
        jQuery('#date').addClass('invalid');
        jQuery(container).append(json.contact_date);
      }
      if(json.contact_name ){
        jQuery('#question').addClass('invalid');
        jQuery(container).append(json.contact_question);
      }

      if(json.contact_email ){
        jQuery('#flo-email').addClass('invalid');
        jQuery(container).append(json.contact_email);
      }

      if(json.message ){
        jQuery('.flo-modal').fadeIn('slow');

        jQuery( form).find('input[type="text"], textarea').val('');

        setTimeout(function(){
          jQuery('.flo-modal').fadeOut('slow');
        },3000);
      }

    }
  });
}

(function ($) {
  var on = $.fn.on, timer;
  $.fn.on = function () {
      var args = Array.apply(null, arguments);
      var last = args[args.length - 1];

      if (isNaN(last) || (last === 1 && args.pop())) return on.apply(this, args);

      var delay = args.pop();
      var fn = args.pop();

      args.push(function () {
          var self = this, params = arguments;
          clearTimeout(timer);
          timer = setTimeout(function () {
              fn.apply(self, params);
          }, delay);
      });

      return on.apply(this, args);
  };
}(this.jQuery || this.Zepto));

(function(window, $, undefined){
  "use strict";

  var breakpoint_small = 750,
      breakpoint_medium = 1024;

  var isDesktop = function() {
    return jQuery(document).width() > breakpoint_medium;
  };

  var isTablet = function() {
    return jQuery(document).width() <= breakpoint_medium && jQuery(document).width() > breakpoint_small;
  };

  var isMobile = function() {
    return jQuery(document).width() <= breakpoint_small;
  };

  var rem = function() {
    return parseInt($("html").css("font-size"), 10);
  }

  $(document).foundation();

  // var floConfig = {
  //   page_padding: 50
  // };

  // Resize ended
  var resizeEnded_timeout;
  window.onresize = function(){
    clearTimeout(resizeEnded_timeout);
    resizeEnded_timeout = setTimeout(function(){
      $(window).trigger("resizeEnded");
    }, 100);
  };

  $(function() {
    FastClick.attach(document.body);
  });
$(function(){
  var
  featured_container_class = ".flo_featured_image_or_slider",
  $featured_container = $(featured_container_class)
  ;

  // Append a logo to the fullscreen featured/slider
  var 
  $logo = $(".flo_header_on_flo_slider .flo_header.logo_position_slider_center .flo_header_logo")
  ;
  
  if ($logo.length) {
    var 
    new_logo_url = floConfig.flo_featured_logo,
    $new_logo = $logo.clone()
    ;
    if (new_logo_url != undefined) $new_logo.find("img").attr("src", new_logo_url);
    setTimeout(function(){
      $new_logo.appendTo(featured_container_class)
    }, 500);
  }

  // Add the "featured_size_full_width" class to the body if the container doesn't have the "size-full_screen" class and set the side elements vertically to the middle of the slider
  var
  class_fullscreen_for_body = "featured_size_full_width",
  class_fullscreen = "size-full_screen"
  ;
  if ($featured_container.length && $("body").hasClass(".flo_header_on_flo_slider") && !$featured_container.hasClass(class_fullscreen)) {
    var
    featured_height = $featured_container.height(),
    featured_height_half_vh = featured_height * 100 / screen.height / 2 + "vh"
    ; 
    console.log(featured_height_half_vh);
    $("body").prepend("<style>@media (min-width:678px){.flo_header_scroll_down, .flo_header_addons { top: " + featured_height_half_vh + "!important } }</style>");
    $("body").addClass(class_fullscreen_for_body);
  };
});

/* SMOOTH FEATURED IMAGE APPEAR START */
jQuery.fn.extend({
  ensureLoad: function(handler) {
    return this.each(function() {
      if(this.complete) {
        handler.call(this);
      } else {
        $(this).load(handler);
      }
    });
  }
});
$(function(){
  var
  $featured_image = $(".flo_featured_image_or_slider .flo_featured_image")
  ;
  if ($featured_image.length){
    var
    url = $featured_image
      .css('background-image')
      .replace(/^url\(['"]?/,'')
      .replace(/['"]?\)$/,'')
    ;

    $('<img/>').attr('src', url).ensureLoad(function() {
      $featured_image.css("opacity", "1");   
    });

  }

});
/* SMOOTH FEATURED IMAGE APPEAR END */

/* CLASS FOR FEATURED IMAGE HOVER START */ 
$(function(){
  var
  $container = $(".flo_featured_image_or_slider:not(.flo_featured_image_hover_effect_disabled)"),
  hover_class = "flo_featured_image_or_slider_hover"
  ;
  $container.hoverIntent(
    {
      over: function(){
        $("body").addClass(hover_class);
      },
      out: function(){
        $("body").removeClass(hover_class);
      }
    }, 10
  );

});
/* CLASS FOR FEATURED IMAGE HOVER END */ 
})(window, jQuery.noConflict());