/**
 * Created by flo on 2/17/16.
 */
function floSendMail( form, container){
  jQuery('#flo-name').removeClass('invalid');
  jQuery('#flo-email').removeClass('invalid');

  jQuery(container).html('');

  //jQuery('#flo-loading').css('background','rgba(255,255,255,0.2)');
  //jQuery('#flo-loading').fadeIn('slow'); // loading effect
  jQuery.ajax({
    url: ajaxurl,
    data: '&action=floSendContact&'+jQuery( form ).serialize(),
    type: 'POST',
    dataType: "json",
//      cache: false,
    success: function (json) {

      //jQuery('#flo-loading').fadeOut('slow'); // loading effect

      if(json.contact_name ){
        jQuery('#flo-name').addClass('invalid');
        jQuery(container).append(json.contact_name);
      }

      if(json.contact_name ){
        jQuery('#location').addClass('invalid');
        jQuery(container).append(json.contact_location);
      }

      if(json.contact_name ){
        jQuery('#date').addClass('invalid');
        jQuery(container).append(json.contact_date);
      }
      if(json.contact_name ){
        jQuery('#question').addClass('invalid');
        jQuery(container).append(json.contact_question);
      }

      if(json.contact_email ){
        jQuery('#flo-email').addClass('invalid');
        jQuery(container).append(json.contact_email);
      }

      if(json.message ){
        jQuery('.flo-modal').fadeIn('slow');

        jQuery( form).find('input[type="text"], textarea').val('');

        setTimeout(function(){
          jQuery('.flo-modal').fadeOut('slow');
        },3000);
      }

    }
  });
}

(function ($) {
  var on = $.fn.on, timer;
  $.fn.on = function () {
      var args = Array.apply(null, arguments);
      var last = args[args.length - 1];

      if (isNaN(last) || (last === 1 && args.pop())) return on.apply(this, args);

      var delay = args.pop();
      var fn = args.pop();

      args.push(function () {
          var self = this, params = arguments;
          clearTimeout(timer);
          timer = setTimeout(function () {
              fn.apply(self, params);
          }, delay);
      });

      return on.apply(this, args);
  };
}(this.jQuery || this.Zepto));

(function(window, $, undefined){
  "use strict";

  var breakpoint_small = 750,
      breakpoint_medium = 1024;

  var isDesktop = function() {
    return jQuery(document).width() > breakpoint_medium;
  };

  var isTablet = function() {
    return jQuery(document).width() <= breakpoint_medium && jQuery(document).width() > breakpoint_small;
  };

  var isMobile = function() {
    return jQuery(document).width() <= breakpoint_small;
  };

  var rem = function() {
    return parseInt($("html").css("font-size"), 10);
  }

  $(document).foundation();

  // var floConfig = {
  //   page_padding: 50
  // };

  // Resize ended
  var resizeEnded_timeout;
  window.onresize = function(){
    clearTimeout(resizeEnded_timeout);
    resizeEnded_timeout = setTimeout(function(){
      $(window).trigger("resizeEnded");
    }, 100);
  };

  $(function() {
    FastClick.attach(document.body);
  });
// Mosaic type como - two blocks animation
jQuery(function(){
  var
  $mosaic = $(".flo_mosaic_type_como"),
  $block_under = $mosaic.find(".block:nth-child(2)"),
  $block_top = $block_under.next(),
  class_hover = "hover",
  class_no_hover = "no_hover",
  set_hover = function($who, what){
    if (what == true) {
      $who.addClass(class_hover)
      $who.removeClass(class_no_hover)
    } else if (what == false) {
      $who.removeClass(class_hover)
      $who.addClass(class_no_hover)
    }
  }  
  ;

  var
  is_hover = false,
  animation_ongoing = false
  ;

  $block_under.hoverIntent({
    over: function(){
      is_hover = true;

      if (animation_ongoing == false) {
        animation_ongoing = true;
        set_hover($block_top, true);
      } 

      setTimeout(function(){
        animation_ongoing = false;
        if (!is_hover) {
          animation_ongoing = true;
          set_hover($block_top, false)
        }
      }, 1610);

    },
    out: function(){
      is_hover = false;
      
      if (animation_ongoing == false) {
        animation_ongoing = true;
        set_hover($block_top, false);
      } 

      setTimeout(function(){
        animation_ongoing = false;
        if (is_hover) {
          animation_ongoing = true;
          set_hover($block_top, true)
        }
      }, 1610);
      
    },
    timeout: 200
  })

});})(window, jQuery.noConflict());