/**
 * Created by flo on 2/17/16.
 */
function floSendMail( form, container){
  jQuery('#flo-name').removeClass('invalid');
  jQuery('#flo-email').removeClass('invalid');

  jQuery(container).html('');

  //jQuery('#flo-loading').css('background','rgba(255,255,255,0.2)');
  //jQuery('#flo-loading').fadeIn('slow'); // loading effect
  jQuery.ajax({
    url: ajaxurl,
    data: '&action=floSendContact&'+jQuery( form ).serialize(),
    type: 'POST',
    dataType: "json",
//      cache: false,
    success: function (json) {

      //jQuery('#flo-loading').fadeOut('slow'); // loading effect

      if(json.contact_name ){
        jQuery('#flo-name').addClass('invalid');
        jQuery(container).append(json.contact_name);
      }

      if(json.contact_name ){
        jQuery('#location').addClass('invalid');
        jQuery(container).append(json.contact_location);
      }

      if(json.contact_name ){
        jQuery('#date').addClass('invalid');
        jQuery(container).append(json.contact_date);
      }
      if(json.contact_name ){
        jQuery('#question').addClass('invalid');
        jQuery(container).append(json.contact_question);
      }

      if(json.contact_email ){
        jQuery('#flo-email').addClass('invalid');
        jQuery(container).append(json.contact_email);
      }

      if(json.message ){
        jQuery('.flo-modal').fadeIn('slow');

        jQuery( form).find('input[type="text"], textarea').val('');

        setTimeout(function(){
          jQuery('.flo-modal').fadeOut('slow');
        },3000);
      }

    }
  });
}

(function ($) {
  var on = $.fn.on, timer;
  $.fn.on = function () {
      var args = Array.apply(null, arguments);
      var last = args[args.length - 1];

      if (isNaN(last) || (last === 1 && args.pop())) return on.apply(this, args);

      var delay = args.pop();
      var fn = args.pop();

      args.push(function () {
          var self = this, params = arguments;
          clearTimeout(timer);
          timer = setTimeout(function () {
              fn.apply(self, params);
          }, delay);
      });

      return on.apply(this, args);
  };
}(this.jQuery || this.Zepto));

(function(window, $, undefined){
  "use strict";

  var breakpoint_small = 750,
      breakpoint_medium = 1024;

  var isDesktop = function() {
    return jQuery(document).width() > breakpoint_medium;
  };

  var isTablet = function() {
    return jQuery(document).width() <= breakpoint_medium && jQuery(document).width() > breakpoint_small;
  };

  var isMobile = function() {
    return jQuery(document).width() <= breakpoint_small;
  };

  var rem = function() {
    return parseInt($("html").css("font-size"), 10);
  }

  $(document).foundation();

  // var floConfig = {
  //   page_padding: 50
  // };

  // Resize ended
  var resizeEnded_timeout;
  window.onresize = function(){
    clearTimeout(resizeEnded_timeout);
    resizeEnded_timeout = setTimeout(function(){
      $(window).trigger("resizeEnded");
    }, 100);
  };

  $(function() {
    FastClick.attach(document.body);
  });
// !!! Need to adjust the position of elements in slide to not cross the header elements for all types of headers

// Adjust top margin of page and slider height so the slider would fill the page till the bottom of the browser.
$(document).ready(function(){
  var $flo_header_on_flo_slider = $(".flo_header_on_flo_slider");
  if ($flo_header_on_flo_slider.length) {
    $(window).on("resize", function() {
      var $flo_slider, $menu_slider_bottom, menu_slider_bottom_height, page_margin_top, slider_height;
      page_margin_top = $(".flo_page").css("margin-top");
      $flo_slider = $flo_header_on_flo_slider.find("header ~ .flo_slider");
      $flo_slider.css("height", "calc(100vh - " + page_margin_top + ")");
      slider_height = $flo_slider.height();
      $menu_slider_bottom = $("header.menu_position_slider_bottom .flo_header_menu");
      menu_slider_bottom_height = $menu_slider_bottom.outerHeight(true);
      var header_top = $(".flo_header").position().top 
      if (isDesktop()) 
        $menu_slider_bottom.css({
          "top": (slider_height - header_top - menu_slider_bottom_height)/rem() + "rem",
          "opacity": 1
        });
    }).trigger("resize");
  }
});

/* MENU SLIDER BOTTOM START */
$(document).ready(function(){
  if ($(".flo_header.menu_position_slider_bottom").length){
    // Menu Slider Bottom: invert animations for second level menu
    var menu_items_places = [".flo_header_menu", ".left_side", ".right_side"];
    menu_items_places.forEach(function(place_selector, index){
      menu_items_places[index] = ".flo_header " + place_selector + " > ul > li";
    });
    var $menu_items = $(menu_items_places.join(" , "));

    var hover_timeout = 100;
    var dropdown_animate = function($menu_items, animation_in, animation_out){
      $menu_items.hoverIntent({
        over: function(){
          if (isDesktop()) MotionUI.animateIn($(this).children("ul"), animation_in);
        },
        out: function(){
          if (isDesktop()) MotionUI.animateOut($(this).children("ul"), animation_out);
        },
        timeout: hover_timeout
      });    
    }

    // Second level menu
    dropdown_animate(
      $menu_items,
      "fade-in",
      "fade-out"
    );

    // Set slider dots position on top of menu
    var menu_height = $(".flo_header_menu").outerHeight(true);
    $(window).on("resizeEnded", function(){
      if (isDesktop()) {
        var menu_height_rem = menu_height/rem() + "rem";
        $(".slick-dots").css("margin-bottom", menu_height_rem);
        $(".slide_content").css("padding-bottom", menu_height_rem);
      }
    }).trigger("resizeEnded");

  }
});
/* MENU SLIDER BOTTOM END */})(window, jQuery.noConflict());