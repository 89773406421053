/**
 * Created by flo on 2/17/16.
 */
function floSendMail( form, container){
  jQuery('#flo-name').removeClass('invalid');
  jQuery('#flo-email').removeClass('invalid');

  jQuery(container).html('');

  //jQuery('#flo-loading').css('background','rgba(255,255,255,0.2)');
  //jQuery('#flo-loading').fadeIn('slow'); // loading effect
  jQuery.ajax({
    url: ajaxurl,
    data: '&action=floSendContact&'+jQuery( form ).serialize(),
    type: 'POST',
    dataType: "json",
//      cache: false,
    success: function (json) {

      //jQuery('#flo-loading').fadeOut('slow'); // loading effect

      if(json.contact_name ){
        jQuery('#flo-name').addClass('invalid');
        jQuery(container).append(json.contact_name);
      }

      if(json.contact_name ){
        jQuery('#location').addClass('invalid');
        jQuery(container).append(json.contact_location);
      }

      if(json.contact_name ){
        jQuery('#date').addClass('invalid');
        jQuery(container).append(json.contact_date);
      }
      if(json.contact_name ){
        jQuery('#question').addClass('invalid');
        jQuery(container).append(json.contact_question);
      }

      if(json.contact_email ){
        jQuery('#flo-email').addClass('invalid');
        jQuery(container).append(json.contact_email);
      }

      if(json.message ){
        jQuery('.flo-modal').fadeIn('slow');

        jQuery( form).find('input[type="text"], textarea').val('');

        setTimeout(function(){
          jQuery('.flo-modal').fadeOut('slow');
        },3000);
      }

    }
  });
}

(function ($) {
  var on = $.fn.on, timer;
  $.fn.on = function () {
      var args = Array.apply(null, arguments);
      var last = args[args.length - 1];

      if (isNaN(last) || (last === 1 && args.pop())) return on.apply(this, args);

      var delay = args.pop();
      var fn = args.pop();

      args.push(function () {
          var self = this, params = arguments;
          clearTimeout(timer);
          timer = setTimeout(function () {
              fn.apply(self, params);
          }, delay);
      });

      return on.apply(this, args);
  };
}(this.jQuery || this.Zepto));

(function(window, $, undefined){
  "use strict";

  var breakpoint_small = 750,
      breakpoint_medium = 1024;

  var isDesktop = function() {
    return jQuery(document).width() > breakpoint_medium;
  };

  var isTablet = function() {
    return jQuery(document).width() <= breakpoint_medium && jQuery(document).width() > breakpoint_small;
  };

  var isMobile = function() {
    return jQuery(document).width() <= breakpoint_small;
  };

  var rem = function() {
    return parseInt($("html").css("font-size"), 10);
  }

  $(document).foundation();

  // var floConfig = {
  //   page_padding: 50
  // };

  // Resize ended
  var resizeEnded_timeout;
  window.onresize = function(){
    clearTimeout(resizeEnded_timeout);
    resizeEnded_timeout = setTimeout(function(){
      $(window).trigger("resizeEnded");
    }, 100);
  };

  $(function() {
    FastClick.attach(document.body);
  });
$(function(){
  var video_popup_class = "flo_video_popup";
  var $video_popup = function(){ return $("." + video_popup_class);}

  var slide_to_popup = function() {
    $('html, body').animate({
      scrollTop: $video_popup().offset().top - 30
    }, 777);
  }

  var create_container = function(initial_slide) {
    // Create video container
    var
    video_popup_html = [
      "<div class='close_wrap'>",
        "<div class='close_button flo-icon-flo-close'></div>",
      "</div>",
      "<div class='video_container'></div>",
      "<div class='video_thumbnails_slider'></div>"
    ].join(" "),
    $video_popup = $("<div class='hidden " + video_popup_class + "'>").html(video_popup_html),
    $close_button = $video_popup.find(".close_button"),
    $video_container = $video_popup.find(".video_container"),
    $video_thumbnails_slider = $video_popup.find(".video_thumbnails_slider"),
    first_slide = initial_slide ? initial_slide : 0
    ;

    // Close button action
    $close_button.click(function(){
      $video_popup.addClass("hidden");
    });

    // Append slides to video and thumbnails sliders
    $(".flo_post.video").each(function(){
      var
      video_title = $(this).find(".title").text(),
      video_categories = $(this).find(".post_category").first().text(),
      video_date = $(this).find(".post_date").first().text(),
      video_link = $(this).attr("data-video-url"),
      video_href = $(this).attr("href"),
      feat_src = $(this).attr("data-feat-src")
      ;
      $video_thumbnails_slider.append(
        $([
          "<div class='video_thumbnail' data-video-url='" + video_link + "' data-feat-img='"+feat_src+"' >",
            "<div class='video_thumbnail_bgi' style='background-image: url(", feat_src ,");'></div>",
            "<div class='video_thumbnail_title_wrap'>",
              "<h2 class='title'>", video_title ,"</h2>",
              "<h6 class='categories'>", video_categories ,"</h6>",
              "<h6 class='date'>", video_date ,"</h6>",
              "<a href='", video_href ,"'>Read more<i class='flo-icon-right-dir'></i></a>",
            "</div>",
          "</div>"
        ].join(""))
      );
    });

    $(".flo_post_list").before($video_popup);

    $video_thumbnails_slider.slick({
      variableWidth: true,
      centerMode: true,
      arrows: false,
      dots: false,
      focusOnSelect: true,
      adaptiveHeight: true,
      initialSlide: first_slide
    });

    // VIDEO WORKS START
    var
    video_destroy = function(){
      $video_container.floVideo("destroy");
      $video_container.empty();
    },
    video_create = function(){
      var
      $current_slide = function(){ return $video_thumbnails_slider.find(".slick-current"); },
      video_url = $current_slide().attr("data-video-url"),
      background_image = $current_slide().attr("data-feat-img")
      ;

      function is_iframe(s) {
        return s.indexOf("<iframe") > -1
      }

      function create_video(){
        var
        url = $video_container.attr("data-video-url")
        ;
        if (!is_iframe(url)){
          $video_container.floVideo("init");
          $video_container.floVideo("create")
        } else {
          $video_container.html(url);
        }
      }

      $video_container.attr("data-video-url", video_url);

      // if (background_image != "undefined") {
      //   $video_container.css("background-image", "url('"+background_image+"')");
      // } else {
      //   create_video();
      // }
      //
      // // Creating the video
      // $video_container.click(function(){
      //   if (background_image != "undefined") {
      //     create_video();
      //   }
      // });

      create_video();

    }
    ;

    // Initial create
    video_create();

    $video_thumbnails_slider.on("beforeChange", function(){
      video_destroy();
    });

    $video_thumbnails_slider.on("afterChange", function(){
      video_create();
    });

    // VIDEO WORKS END
    setTimeout(function(){
      $video_popup.removeClass("hidden");
      slide_to_popup();
      $video_thumbnails_slider.slick('setPosition');
    }, 777);

  };

  var goToVideo = function (slide_index) {
    var $slider = $video_popup().find(".video_thumbnails_slider")
    if (slide_index !== undefined){
      $slider.slick("slickGoTo", slide_index);
      slide_to_popup();
    }
  }

  if (isTablet() || isDesktop()) $(".flo_post.video .feat_img").click(function(e) {
    e.preventDefault();
    if ($video_popup().length) {
      $video_popup().removeClass("hidden");
      goToVideo($(this).parent().index());
    } else {
      create_container($(this).parent().index());
    }
  });
});
})(window, jQuery.noConflict());